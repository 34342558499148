@import "../../style.scss";

$accent: $accentBlue;

.home {

    .headerTop {
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;
        width: 100vw;
        margin: 0 auto;
        height: 100vh !important;
        overflow: hidden;

        .container {
            display: flex;
            justify-content: center;
            padding-left: 10vw;
            padding-right: 10vw;
            height: 50vh;
            max-width: calc(1920px - 5vw);
            margin-top: 40vh;
            color: $textColor;
            z-index: 10;

            h1 {
                max-width: 600px;
                margin-bottom: 20px;
                color: $bg;
            }

            .bottomLeft {
                display: flex;
                max-width: 90%;
                bottom: 10%;
                gap: 20px;
                left: 5%;
                z-index: 1;

                a {
                    text-decoration: none;
                }
            }
        }

        .arrows {
            display: flex;
            position: absolute;
            right: 5%;
            bottom: 5vh;
            gap: 20px;
            z-index: 10;
        }

        .popUp {
            display: flex;
            gap: 30px;
            position: absolute;
            left: 0;
            top: 40vh;
            padding: 20px;
            background: rgba($accent, 0.7);
            color: $bg;
            z-index: 5;

            .left {
                display: flex;
                flex-direction: column;

                a {
                    color: $bg;
                    text-decoration: none;
                }
            }

            .right {
                display: flex;
                align-items: center;

                svg {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .slideshow {
            width: 100vw;
            height: 100vh;
            background: $textColor;

            .slick-slider {
                height: inherit;

                .slick-list {
                    height: inherit;
                    opacity: 0.75;
                }

                .slick-track {
                    height: inherit;

                    div {
                        height: inherit;
                    }

                    img,
                    video {
                        width: 100vw;
                        height: inherit;
                        aspect-ratio: 16 / 9;
                        object-fit: cover;
                        z-index: 0;
                    }
                }

                .arrow {
                    display: flex;
                    position: absolute;
                    bottom: 5vh;
                    align-items: center;
                    justify-content: center;
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                    border: 1px solid $bg;
                    transition: 0.2s ease-in-out;
                    z-index: 10;
                    
                    @include tablet {
                        bottom: 20vh;
                    }
                }

                .arrow.next {
                    right: 5%;
                    background: $accent;
                    border-color: $accent;
                    color: $bg;

                    &:hover {
                        background: $bg;
                        border-color: $bg;
                        color: $accent;
                    }
                }

                .arrow.prev {
                    right: calc(5% + 70px);
                    color: $bg;

                    &:hover {
                        background: $bg;
                        color: $accent;
                    }
                }
            }
        }
    }

    .leistungen,
    .news {

        .grid {
            display: flex;
            flex-wrap: wrap;
            gap: 5vh;
            margin-top: 50px;

            @include tablet {
                flex-direction: column;
            }

            .item {
                position: relative;
                width: 48%;
                aspect-ratio: 3 / 2;
                overflow: hidden;

                @include tablet {
                    width: 100%;
                }

                .box {
                    position: absolute;
                    bottom: 0;
                    width: -webkit-fill-available;
                    color: $bg;
                    font-size: 1.5rem;
                    padding: 20px;
                    background: rgba($accent, 0.7);
                    z-index: 10;

                    .top {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .bottom {
                        height: 0;
                        width: -webkit-fill-available;
                        overflow: hidden;
                        transition: 0.2s ease-in-out;

                        span {
                            font-size: initial;
                        }
                    }
                }

                .image {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.2s ease-in-out;
                }

                &:hover {
                    cursor: pointer;

                    .image {
                        transform: scale(1.05);
                    }

                    .box {

                        .bottom {
                            height: 50px;
                        }
                    }
                }
            }
        }
    }

    .team {
        background: $bgBox;
        margin-top: 20vh;
        margin-bottom: 20vh;

        .container {
            display: flex;
            flex-direction: row;
            gap: 5vh;
            padding: 5%;

            @include tablet {
                flex-direction: column;
            }

            .left {
                width: 50%;

                @include tablet {
                    width: 100%;
                }
            }

            img {
                width: 50%;
                max-width: 700px;
                margin-top: -10%;

                @include tablet {
                    margin-top: 0;
                    width: 100%;
                }
            }

            p {
                margin-bottom: 5vh;
            }
        }
    }

    .news {

        .grid {

            .item {
                width: 30%;
                aspect-ratio: 5 / 6;
            }
        }
    }
}